import { useMutation } from '@apollo/client/index.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import UserForm, { FormValues, roleOptions, validate } from '@asaprint/asap/components/forms/UserForm.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import {
  ADMIN_EDIT_USER_TITLE,
  ADMIN_EDIT_USER_UPDATED_MESSAGE,
  BUTTON_UPDATE,
} from '@asaprint/asap/locales/client.js';
import { ADMIN_USERS_ROUTE, DASHBOARD_ROUTE } from '@asaprint/asap/routes.js';
import {
  EditUser_LoadQuery,
  EditUser_LoadQueryVariables,
  EditUser_SaveMutation,
  EditUser_SaveMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import { Permission } from '@asaprint/common/access.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import Loading from '@engined/client/components/Loading.js';
import { ConnectedSubmitButtons } from '@engined/client/components/SubmitButtons.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EditUser_Load, EditUser_Save } from '@asaprint/asap/routes/__authenticated/admin/users/$id.edit.graphql';

const logger = getLogger('@asap/routes/admin/users/$id.edit');

interface OwnProps {}

type Props = OwnProps;

const Page: React.FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useLocale();
  const { loading, data, error } = useQuery<EditUser_LoadQuery, EditUser_LoadQueryVariables>(EditUser_Load, {
    variables: { id },
  });
  const [userEditExecute] = useMutation<EditUser_SaveMutation, EditUser_SaveMutationVariables>(EditUser_Save);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: OnSubmit<FormValues> = async ({ passwordConfirmation, color, moneyUser, role, ...values }) => {
    try {
      const response = await userEditExecute({
        variables: {
          input: {
            ...values,
            role: role?.value ?? null,
            color: color.slice(1),
            moneyUserId: moneyUser?.value ?? null,
          },
        },
      });
      enqueueSnackbar(t(ADMIN_EDIT_USER_UPDATED_MESSAGE, { username: response.data.userSave.username }), {
        variant: 'success',
      });
      navigate(ADMIN_USERS_ROUTE, { state: { ignoreFormPrompt: true } });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  const user = data?.user;
  const moneyUsers = data?.moneyUsers?.edges;
  const moneyUserOptions = useMemo<Option[]>(
    () =>
      moneyUsers
        ? moneyUsers.map((e) => ({
            label: displayName(e.node),
            value: e.node.id,
          }))
        : [],
    [moneyUsers],
  );

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faUsers} /> {t(ADMIN_EDIT_USER_TITLE)}
          </>
        }
      />

      <PagePaper>
        {error || (loading && !data) ? (
          <Loading error={error} />
        ) : (
          <Form
            defaultValues={{
              id: user.id,
              updatedAt: user.updatedAt,
              active: user.active,
              firstName: user.firstName ?? '',
              lastName: user.lastName ?? '',
              role: roleOptions.find((u) => u.value === user.role),
              username: user.username,
              email: user.email,
              password: '',
              passwordConfirmation: '',
              moneyUser: moneyUserOptions.find((u) => u.value === user.moneyUserId),
              color: `#${user.color ?? '000000'}`,
            }}
            onSubmit={onSubmit}
            validate={validate}
          >
            <UserForm passwordRequired moneyUserOptions={moneyUserOptions} />
            <ConnectedSubmitButtons submitLabel={t(BUTTON_UPDATE)} backTo={ADMIN_USERS_ROUTE} />
          </Form>
        )}
      </PagePaper>
    </>
  );
};

export default authorized(Permission.UsersManage)(Page);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  if (ENV.SERVER) {
    const result = await apollo.query({ query: EditUser_Load, variables: { id: params.id } });
    return result.data;
  }
  return null;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Administrácia',
    },
    {
      text: 'Používatelia',
      to: ADMIN_USERS_ROUTE,
    },
    {
      text: 'Úprava používatela',
    },
  ],
  meta: ({ locale: { t }, meta }: MetaFunctionArgs) => ({
    title: `Nový používateľ | Používatelia | Administrácia | ${meta.title}`,
  }),
};
